<template>
    <!-- Header -->
    <div class="header">    
        <div class="top-left" @click="$router.push('/')">
          <img src="../assets/img/logo_daphina.png" alt="Daphnia Logo" />
          <div class="logo-txt">
            <h5>{{headerData.name || 'YSA 2024'}}</h5>
            <p>{{headerData.desc || 'Young Scientist Association'}}</p>
          </div>
        </div>
        <div class="top-middle">
          <el-autocomplete
            popper-class="my-autocomplete"
            v-model="state"
            :fetch-suggestions="querySearch"
            placeholder="Find something interesting"
            @select="handleSelect">
            <i
              class="el-icon-search el-input__icon"
              slot="prefix"
              @click="handleIconClick">
            </i>
            <template slot-scope="{ item }">
              <div class="name">{{ item.name }}</div>
              <span class="addr">{{ item.desc }}</span>
            </template>
          </el-autocomplete>
        </div>
        <div class="accessBtn">        
          <el-button type="primary" @click="$router.push('/issue1')">Find more...</el-button>
          <!-- <el-avatar
            src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
          ></el-avatar> -->
          <el-button plain @click="clickLogin">{{headerData.login? 'Exit':'Login'}}</el-button>
        </div>
    </div>
</template>
<script>
export default {
  props:{
    headerData: {
      type: Object,
      required: true,
    }, 
  },
  data() {
    return {
      isFocus: false,
      state: '',
      project_list: [],
    }
  },
  computed: {
    isSearch() {
      return this.isFocus;
    },
  },
  watch: {
    headerData: {
      deep: true,
      handler(val) {
        this.setHeader(val);
      },
    },
  },
  mounted(){
    // console.log(this.headerData)
    this.project_list = this.loadAll();
  },
  methods:{    
    setHeader(val){
      console.log(val);
    },
    clickLogin(){
      // window.location.href ='https://www.daphina.net/planktonDB' ;
      window.location.href ='#' ;
    },   
    querySearch(queryString, cb) {
      var project_list = this.project_list;
      var results = queryString ? project_list.filter(this.createFilter(queryString)) : project_list;             
      // 调用 callback 返回建议列表的数据
      cb(results);

    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },       
    handleIconClick(ev) {
      console.log('handleIconClick->')
      console.log(ev);
    },  
    handleSelect(project_list) {
      console.log(project_list);
      console.log(project_list.id)
      if(project_list.id ===1){
        window.location.href ='#/issue2' ;
      }
      if(project_list.id ===2){
        window.location.href ='#/issue1' ;
      }
    },    
    loadAll() {
      return [
          {
            id: 1,
            name:'The Young Scientist | Volume 2, Issue 1, 2023',
            title: 'Plankton Community',
            desc: 'Survey of plankton community of the coastal environment of Shenzhen: Phytoplankton are the primary producer and the key oxygen producer in the aquatic environment. They form the basis of the food web; They are considered important proxy to environmental pollution; They play an important role in the geochemical cycles of important elements. This project focuses on the seasonal variation and spatial distribution of phytoplankton in the coastal environment of Shen Zhen. Sampling of phytoplankton will be conducted quarterly. Phytoplankton species will be observed under light microscope and the image data will be used to establish a training data set for automated identification by AI. A database of phytoplankton as well as their occurrence pattern will be analyzed and linked with environmental parameters, through which the project aims at (1) facilitate automated identification of phytoplankton specimens collected in Shen Zhen and (2) provide the foundation for future modeling of environmental events such as harmful algal bloom (3) establish the biodiversity and ecosystem health index of Shen Zhen coastal environment.',
            src: require("../assets/img/ysa/v1i1/i1_cover.jpg"),
          },
          {
            id: 2,
            name:'The Young Scientist | Volume 1, Issue 1, 2022',
            title: 'Benthos Phototaxis',
            desc: 'Benthic organisms is a crucial part in the marine ecosystem. Bryozoans are a group of marine benthos that have an indispensable role in maintaining marine biodiversity but are least known by the public. From the coastal water to 5500m in the deep sea, and from the equator to the polar seas, bryozoans are highly successful in colonizing different habitats. They grow and cover biotic or abiotic surfaces, and hence they possess severe biofouling problem to other sessile marine invertebrates as well as to the mankind. Up-to-date, little is known about their larval settlement mechanism, especially how their response to light facilitate the choice of settling substrate. This project aims at developing auto tracking algorithm for automated recording and analysis of bryozoans larval phototactic response. the project involved both wet lab and dry lab computational experiments to capture and analyze some unknown larval behavior.',
            src: require("../assets/img/ysa/v1i1/i1_cover.jpg"),
          },
          {
            id: 3,
            name:'Study of the Feeding behavior of Marine flatworms',
            title: 'Feeding Behavior',
            desc: 'Flatworms, or Platyhelminthes, are of great significance in the evolution of animals, as its emergence is a huge leap in the history of evolution. It marks the transition from the aquatic to terrestrial environments, and from fixed/floating to free-living crawling lifeforms. Because of its strong regeneration power, platyhelminthes are good model for studying molecular mechanism of cell differentiation, dedifferentiation, animal behavior and chromosome. This project will deploy the infrared imaging tracking equipment (developed by ourselves), and will use the AI machine vision technology to study the feeding behavior of this group of animal.',
            src: require("../assets/img/ysa/v1i1/i1_cover.jpg"),
          },        
          {
            id: 4,
            name:'3D microscopy of phytoplankton and 3D image reconstruction',
            title: '3D Reconstruction',
            desc: '3D microscopy of phytoplankton and 3D image reconstruction. Capture 3D images of phytoplankton using the light sheet sectioning technique, reconstruct 3D images using machine vision techniques, and present the data using appropriate graphics to convey important biological information. The project covers optical physics, machine vision and 3D digital modeling.',
            src: require("../assets/img/ysa/v1i1/i1_cover.jpg"),
          },
          {
            id: 5,
            name:'Detection of objects with unstable morphology based on deep learning',
            title: 'Object Detection',
            desc: 'Most of the existing AI object tracking algorithms target objects with relative stable morphology, but they usually have a bad performance on tracking objects with changeable morphology. This limits their application scopes. This project will apply AI and image processing techniques to design algorithms specifically for tracking objects with changeable morphology, which can be very useful for plankton behavior observation, cell research in medicine, etc. This project involves knowledge and techniques of deep learning, image processing and data analysis.',
            src: require("../assets/img/ysa/v1i1/i1_cover.jpg"),
          },
          {
            id: 6,
            name:' AI based object recognition and embedded edge computing',
            title: 'Edge Computing',
            desc: 'AI based object recognition and embedded edge computing. Modern machine vision technology based on AI has been successfully applied in many fields with high efficiency and performance. It has greatly reduced the cost of human labor and time. “AI for Science” has also been rated as one of the top 10 technology trends in 2022 by Ailibaba DAMO Academy. In addition, the application of AI is growing towards miniaturization, low cost, and low power consumption. In this project, AI technology will be applied to solve the widely demanded object recognition problem in plankton science research. Then the high-performance recognition algorithms will be deployed on the embedded platform to implement edge computing. We expect that this project will provide efficient and smart tools for plankton science research, promoting the development of “AI for Plankton Science”. This project involves knowledge and techniques of deep learning, embedded computer system, and edge computing.',
            src: require("../assets/img/ysa/v1i1/i1_cover.jpg"),
          },
        ]
    },
  }
}
</script>

<style lang="less" scoped>
.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .addr {
      font-size: 12px;
      color: #b4b4b4;
    }
    .highlighted .addr {
      color: #ddd;
    }
  }
}
.header {
  // background-color: #373d41;
  color: #000;
  font-size: 16px;
  text-align: center;
  // line-height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;  
  .logo-txt{
    padding: 10px;
  }
  .logo-txt p{
    margin: 0 auto;
    font-size: 12px;
  }
  .logo-txt h5{
    margin: 0 auto;
  }
  .top-left {
    display: flex;
    align-items: center;
    span {
      margin-left: 15px;
    }
  }
  .top-left:hover{
    cursor: pointer;
  }
  img {
    height: 55px;
    padding: 5px;
  }
  .el-autocomplete {
    width: 100%;
  }
  .top-middle {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .header{
    .logo-txt {
      display: none !important ;
    }
    .accessBtn{
      display: none !important ;
    }    
    .top-middle {
      width: 100%;
    }
  }
}
</style>